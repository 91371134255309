import React, { useState, useMemo } from "react"
import ReactPaginate from 'react-paginate';

import { graphql, useStaticQuery } from "gatsby"
import { BlogCard } from "../components/BlogCard"
import { ArrowLeftIcon, ArrowRightIcon } from "../components/Icons"

import BgImage from "../img/bg.png"

import Layout from "../components/Layout"
import Seo from "../components/Seo"

const query = graphql`
  query BlogQueryEN {
    datoCmsBlogPage(locale: { eq: "en" }) {
      heading
      description
      seo {
        title
        description
      }
      buttonCta
      callToAction
    }
    allDatoCmsArticleCategory(filter: { locale: { eq: "en" } }) {
      nodes {
        slug
        name
        seo {
          description
        }
        image {
          gatsbyImageData(width: 535, height: 200, layout: FIXED)
          alt
        }
      }
    }
    allDatoCmsBlogArticlesModel(
      filter: { locale: { eq: "en" }, published: {eq: true} }
      sort: { fields: articleDate, order: DESC }
    ) {
      nodes {
        title
        slug
        content {
          ... on DatoCmsCalloutSection {
            id
            title
            calloutContent
          }
          ... on DatoCmsContentSection {
            id
            content
            contentId
          }
          ... on DatoCmsCtaSection {
            id
            ctaText
            buttonName
            buttonLink
          }
          ... on DatoCmsQuoteSection {
            id
            author
            authorRole
            quoteContent
            authorImage {
              alt
              gatsbyImageData
            }
          }
        }
        categories {
          id
          slug   
          name      
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        image {
          gatsbyImageData
          alt
        }
        articleDate
      }
    }
  }
`

const Blog = props => {
  const lang = props.pageContext.langKey
  const [currentPage, setCurrentPage] = useState(0);
  const ITEMS_PER_PAGE = 12;
  const [selectedCategory, setSelectedCategory] = useState('all')

  const handleCategorySelect = (slug) => {
    setSelectedCategory(slug)
  }

  const blogData = useStaticQuery(query)

  const { heading, description, seo, buttonCta,
    callToAction } = blogData.datoCmsBlogPage
  const blogCategories = blogData.allDatoCmsArticleCategory.nodes
  const articles = blogData.allDatoCmsBlogArticlesModel.nodes

  const filteredArticles = useMemo(() => {
    return selectedCategory === 'all'
      ? articles
      : articles.filter(article => {
        return article.categories.some(category => category.slug === selectedCategory);
      });
  }, [articles, selectedCategory]);

  const pageCount = Math.ceil(filteredArticles.length / ITEMS_PER_PAGE);
  const handlePageClick = (data) => {
    let selected = data.selected;
    setCurrentPage(selected);
    window.scrollTo(0, 0);
  };

  const indexOfLastArticle = (currentPage + 1) * ITEMS_PER_PAGE;
  const indexOfFirstArticle = indexOfLastArticle - ITEMS_PER_PAGE;
  const currentArticles = filteredArticles.slice(indexOfFirstArticle, indexOfLastArticle);


  return (
    <Layout lang={lang} location={props.location} buttonCta={buttonCta} callToAction={callToAction}>
      <Seo title={seo.title} slug="blog" description={seo.description} />
      <div className="blog">
        <div
          className="blog__bg"
          style={{ backgroundImage: `url(${BgImage})` }}
        ></div>
        <div className="container">
          <h1
            className="blog__title"
            dangerouslySetInnerHTML={{ __html: heading }}
          />
          <h5
            className="blog__subtitle"
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <div className="blog__category-selectors">
            <button
              key={'all'}
              onClick={() => handleCategorySelect('all')}
              className={selectedCategory === 'all' ? 'active' : ''}
            >
              All
            </button>
            {blogCategories.map((category, index) => (
              <button
                key={index}
                onClick={() => handleCategorySelect(category.slug)}
                className={selectedCategory === category.slug ? 'active' : ''}
              >
                {category.name}
              </button>
            ))}
          </div>
          <div className="blog__container">
            {currentArticles.map((article, index) => {
              return <BlogCard lang={lang} key={index} blog={article} />
            })}
          </div>
          <ReactPaginate
            previousLabel={<ArrowLeftIcon />}
            nextLabel={<ArrowRightIcon />}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            activeClassName={'active'}
            forcePage={currentPage}
          />
        </div>
      </div>
    </Layout>
  )
}

export default Blog
